//jsxhook

import { GetServerSideProps, NextPage } from "next"
import { getActiveFeatureFlagsFromContext } from "../FeatureFlags"
import { GlobalProps } from "../next/GlobalProps"
import { getGlobalServerSideProps } from "../next/GlobalPropsService"
import { CatchAllProps } from "../routing/PageProps"
import { PrismicContentResolver } from "../routing/PrismicContentResolver"
import ParamsPage from "./[...params]"

export const Homepage: NextPage<CatchAllProps> = ParamsPage

export const getServerSideProps: GetServerSideProps<
  GlobalProps
> = async context => {
  const globals = (await getGlobalServerSideProps(context)) as {
    props: GlobalProps
  }
  const prismicPage = await new PrismicContentResolver().resolve(
    { prismicPath: "/", prismicUid: "/" },
    globals.props
  )
  const { features } = getActiveFeatureFlagsFromContext(context)

  context.res.setHeader(
    "Cache-Control",
    "public, s-maxage=600, stale-while-revalidate=900"
  )

  return {
    props: {
      ...globals.props,
      ...(prismicPage || {}),
      features
    }
  }
}

export default Homepage
